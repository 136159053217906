import iheart_png from ".././assets/iheart.png";
import aida_laugh_gif from ".././assets/aida-laugh.gif";
import group_chat_gif from ".././assets/group-chat.gif";
import aunties_gif from ".././assets/aunties.gif";
import ssense_png from ".././assets/ssense.png";
import heben_knows from ".././assets/heben-knows.png";
import stage_png from ".././assets/stage.png";
import hov_png from ".././assets/hov.png";
import whoopi_png from ".././assets/whoopi.png";
import afterlives_png from ".././assets/afterlives.png"
import popup_gif from ".././assets/popup.gif";
import queer_png from ".././assets/queer.png";
import bigtake_png from ".././assets/bigtake.png";

export const carouselData = [
  {
    id: "whoopi",
    image: whoopi_png,
    title: "Grams",
    source: "AudioFlux",
    subtitle: "An audio piece about magic mushrooms that I made as part of the inaugural cohort of Audio Flux. Featured in the Institute for Contemporary Art and the Tribeca Festival.",
    url: "https://www.audioflux.org/circuit01/aaron-edwards",
  },
  {
    id: "bigtake",
    image: bigtake_png,
    title: "Big Take",
    source: "Bloomberg",
    subtitle:
      "I'm currently a story editor for Bloomberg's daily narrative podcast that showcases in-depth, original reporting on one global story each day.",
    url: "https://podcasts.apple.com/us/podcast/big-take/id1578096201/",
  },
  {
    id: "popup",
    image: popup_gif,
    title: "Pop-Up Magazine",
    source: "BAM, Lincoln Center",
    subtitle: "For several years, I produced, directed, and hosted Pop-Up Magazine, a touring 'live magazine' that brought stories to life on stage with an orchestra and visuals.",
    url: "https://www.mcdbooks.com/electric_eel/issue-026",
  },
  {
    id: "ssense",
    image: ssense_png,
    title: "Last laugh: to capture a LOL",
    source: "SSENSE",
    subtitle:
      "A lot of my writing circles ideas about friendship, staying close, and drifting apart. Like this essay about sending voice memos during the pandemic.",
    url: "https://www.ssense.com/en-us/editorial/culture/last-laugh",
  },
  {
    id: "group_chat",
    image: group_chat_gif,
    title: "Long live the group chat",
    source: "The Outline",
    subtitle:
      "As a founding editor of The Outline, I spearheaded special projects that melded design, text, and visuals (this works best on desktop). I also hosted a daily podcast.",
    url: "https://theoutline.com/post/2315/long-live-the-group-chat",
  },
  {
    id: "hov",
    image: hov_png,
    title: "The Book of Hov",
    source: "Roc Nation",
    subtitle: "I wrote and directed audio stories, narrated by Angie Martinez, for JAY-Z's tribute exhibition at The Brooklyn Public Library.",
    url: "https://www.thebookofhov.com/1-introduction",
  },
  {
    id: "afterlives",
    image: afterlives_png,
    title: "Afterlives",
    source: "iHeart",
    subtitle:
      "This series I developed and story-edited unpacks the systems of power that failed Layleen Polanco, an Afro-Latina trans woman who died on Rikers Island.",
    url: "https://podcasts.apple.com/us/podcast/afterlives-the-layleen-polanco-story/id1715577597",
  },
  {
    id: "unread",
    image: iheart_png,
    title: "Unread",
    source: "iHeart",
    subtitle:
      "I story-edited this New York Magazine Best Of 2021 podcast by Chris Stedman about friendship, mental health, and the things we leave behind.",
    url: "https://podcasts.apple.com/us/podcast/unread/id1568341860",
  },
  {
    id: "laugh_aida",
    image: aida_laugh_gif,
    title: "How to laugh when nothing is funny",
    source: "Pop-Up Magazine",
    subtitle:
      "An at-home short about Black humor that I produced and directed, starring Aida Osman (HBO Max's Rap Sh!t).",
    url: "https://www.youtube.com/watch?v=4wGoFgu0leY",
  },
  {
    id: "heben",
    image: heben_knows,
    title: "Heben Only Knows",
    source: "The Bell House",
    subtitle: "I directed and co-wrote this game show, hosted and created by Heben Nigatu. Filmed in collaboration with Irony Point.",
    url: "https://www.instagram.com/p/Cl2dxh4unIB/?img_index=8",
  },
  {
    id: "aunties",
    image: aunties_gif,
    title: "The enduring power of aunties",
    source: "The Outline",
    subtitle:
      "A project I created that combined prose, poetry, and illustration to reflect on the role of aunts in our lives.",
    url: "https://theoutline.com/post/2547/the-enduring-power-of-aunties",
  },
];
